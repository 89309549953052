var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "partners-wrap" }, [
    _c(
      "div",
      { staticClass: "partners-content ec-container" },
      [
        _vm._t("default"),
        _vm._v(" "),
        _vm._l(_vm.partnersList, function (item, index) {
          return _c("div", { key: index, staticClass: "partner-item" }, [
            _c("div", { staticClass: "item-title" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$t("borrow." + item.title)) +
                  "\n            "
              ),
            ]),
            _vm._v(" "),
            _vm.mounted
              ? _c(
                  "div",
                  {
                    staticClass: "partners-box",
                    style: {
                      height: !_vm.isMdScreen && item.isScroll ? ".5rem" : "",
                    },
                  },
                  [
                    item.isScroll
                      ? _c(
                          "div",
                          [
                            _c("div", { staticClass: "cover cover1" }),
                            _vm._v(" "),
                            _c(
                              "vue-seamless-scroll",
                              {
                                key: _vm.scrollKey + index,
                                staticClass: "swiperWidth",
                                attrs: {
                                  data: item.images,
                                  "class-option": _vm.classOption(
                                    item.images.length
                                  ),
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "box-wrap" },
                                  _vm._l(item.images, function (it, ix) {
                                    return _c("div", {
                                      key: ix,
                                      staticClass: "box-item",
                                      style: {
                                        "background-image": "url(" + it + ")",
                                        width: _vm.isMdScreen
                                          ? item.imagesWidth[ix] + "rem"
                                          : item.imagesWidth[ix] / 2 + "rem",
                                        "margin-right":
                                          index === 2 ? ".6rem" : "",
                                      },
                                    })
                                  }),
                                  0
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "cover cover2" }),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          { staticClass: "noScroll-box-wrap" },
                          _vm._l(item.images, function (it, ix) {
                            return _c(
                              "div",
                              { key: ix, staticClass: "noScroll-item" },
                              [
                                _c("div", { staticClass: "title" }, [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.$t("borrow.regulatorList." + ix)
                                      ) +
                                      "\n                        "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("img", {
                                  staticClass: "no-scroll-img",
                                  style: {
                                    width: _vm.isMdScreen
                                      ? item.imagesWidth[ix]
                                      : item.imagesWidthSm[ix],
                                  },
                                  attrs: { src: it },
                                }),
                              ]
                            )
                          }),
                          0
                        ),
                  ]
                )
              : _vm._e(),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }